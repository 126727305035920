import React, { useMemo } from "react";
import {
  TaskFormButton,
  InterventionFormButton,
  CallFormButton,
  TicketFormButton,
  EmailConversationButton
} from "../Form/FormButtons";

import { EntityPopup } from "../../Components/EntityPopup/EntityPopup";
import BreadcrumbsPopup from "../../Components/Breadcrumbs/BreadcrumbsPopup";
import { originTypes } from "../../Helpers/MiscHelper";
import {
  spacePipelineStatusSchema,
  spaceTicketsSchema
  //   spaceTicketsSchema
} from "../../config/schema";
import { getEntity } from "../../Helpers/IOClient";
import { SendNotificationButton } from "../../Components/SendNotificationButton/SendNotificationButton";
import { useCurrentAccountSpace } from "../../Contexts/UserContext";
import {
  moduleTypes,
  canUserEditModule,
  canUserReadModule
} from "../../Helpers/ModulesHelper";
import { LabelTitle } from "../../Helpers/GridHelper";
import { TicketsClipboardButton } from "../../Helpers/HooksHelper";
import EntityReadButton from "../../Components/EntityRead/EntityReadButton";
import { TicketsPdfButton } from "../../Components/Tickets/Tickets";
import { FormattedMessage, useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DeleteButtonContextMenu } from "../../Components/CGrid/DeleteMultipleModal";
import { ProjectStatusTypes } from "../Projects/ProjectsStatusHelper";
import { ColorFilterBars } from "../../Components/FilterList/AdvancedFilter/AdvancedFilterColors/AdvancedFilterColors";
import { TimeTrackingGridFormButton } from "../TimeTracking/TimeTracking";
import {
  createContactFilter,
  createDepartmentFilter,
  createFollowingFilter,
  createIndirectClientFilter,
  createIndirectContractFilter,
  createIndirectDealFilter,
  createIndirectProjectFilter,
  createNotFollowingFilter,
  createOrganizationFilter,
  createOriginFilter,
  createProjectStatusFilter,
  createReadFilter,
  createTeamFilter,
  createUnreadFilter,
  createUserFilter,
  createUpgradedPipelineStatusFilter,
  createTicketTagFilter
  // createPipelineStatusFilter
} from "../../Helpers/FilterHelper";
import { PipelineTypeEnum } from "../Settings/Pipelines/NewPipelineHelper";
import { ConnectedAccountProfile } from "../../Components/Accounts/AccountProfile";
import { TicketTargetDropdown } from "../AdvancedMultiInputs";
import { faAlignJustify } from "@fortawesome/pro-solid-svg-icons";
import {
  faTicketSimple,
  faCalendarAlt
} from "@fortawesome/pro-light-svg-icons";
import { createTicketRatingFilter } from "../../Components/Rating/RatingDropdown";
import { useModalStatusReducer } from "../../Components/Modal/ModalHelperFunctions";
import SendNotificationModal from "../../Components/SendNotificationButton/SendNotificationModal";

export const defaultTicketForm = ({ user, id }) => {
  return {
    TicketTarget: null,
    ReceivedDate: new Date(),
    Contact: null,
    DepartmentTeam: null,
    ManagerAccount: user.Id,
    Description: "",
    Name: "",
    PipelineStatus: null,
    Place: "",
    Region: "",
    Files: [],
    Locale: "",
    Address: "",
    Tags: [],
    PostalCode: "",
    Manager: null,
    Country: undefined,
    Industries: [],
    Followers: id ? [] : [user.Id],
    Image: null,
    Latitude: 0,
    Longitude: 0,
    Status: null
  };
};

export const validateTicketForm = (
  {
    TicketTarget,
    ReceivedDate,
    Pipeline,
    PipelineStatus,

    DepartmentTeam,
    Company,
    StatusDetailId,
    StatusDetail,
    StatusDetailDescription,
    Name,
    Description,
    ManagerAccount,
    Contact
  },
  addError
) => {
  const Status = getEntity(spacePipelineStatusSchema, PipelineStatus);

  if (!Company) addError("Company");
  if (!TicketTarget) addError("TicketTarget");
  // if (!Contact) addError("Contact");
  if (!ReceivedDate) addError("ReceivedDate");
  if (!Name) addError("Name");
  if (!Description) addError("Description");

  if (!Pipeline) addError("Pipeline");
  if (!PipelineStatus) addError("PipelineStatus");
  if (
    Status &&
    Status.Status === TicketsStatusTypes.canceled &&
    !StatusDetailId &&
    !StatusDetail
  )
    addError("StatusDetailId");
  if (!ManagerAccount) addError("ManagerAccount");
};

export const TicketsStatusTypes = {
  open: 1,
  canceled: 3,
  closed: 4,
  draft: 5
};

export const TicketEntityButtons = ({ entity, skeleton, onSuccess }) => {
  const isPositiveStatus =
    entity.Status !== TicketsStatusTypes.canceled &&
    entity.Status !== TicketsStatusTypes.finished;

  return (
    <React.Fragment>
      <TicketFormButton
        className="mr-2"
        skeleton={skeleton}
        canEdit={entity.CanEdit}
        id={entity.Id}
        onSuccess={onSuccess}
      />
      {isPositiveStatus && (
        <CallFormButton
          className="mr-2"
          alt
          skeleton={skeleton}
          formProps={{ ticketId: entity.Id }}
          onSuccess={onSuccess}
        />
      )}
      {isPositiveStatus && (
        <TaskFormButton
          className="mr-2"
          alt
          skeleton={skeleton}
          formProps={{ ticketId: entity.Id }}
          onSuccess={onSuccess}
        />
      )}
      {isPositiveStatus && (
        <TicketFormButton
          className="mr-2"
          alt
          skeleton={skeleton}
          formProps={{ ticketId: entity.Id }}
          onSuccess={onSuccess}
        />
      )}
      {isPositiveStatus && (
        <InterventionFormButton
          className="mr-2"
          alt
          skeleton={skeleton}
          formProps={{ ticketId: entity.Id }}
          onSuccess={onSuccess}
        />
      )}
      {isPositiveStatus && (
        <>
          <TicketFormButton
            skeleton={skeleton}
            onSuccess={onSuccess}
            alt
            className="mr-2"
            duplicate
            id={entity.Id}
          />

          <SendNotificationButton
            skeleton={skeleton}
            onSuccess={onSuccess}
            alt
            Schema={spaceTicketsSchema}
            Id={entity.Id}
          />
        </>
      )}
    </React.Fragment>
  );
};

export const TicketEntityActionsChildren = ({
  entity,
  minimal,
  sendNotificationToggler,
  sidebar,
  detailsView
}) => {
  const isPositiveStatus =
    entity.Status !== ProjectStatusTypes.canceled &&
    entity.Status !== ProjectStatusTypes.finished;

  const AccountSpace = useCurrentAccountSpace();

  return (
    <>
      {!sidebar && !detailsView && (
        <TicketFormButton contextIcons id={entity.Id} />
      )}
      {!detailsView && <TicketFormButton globalEntityView id={entity.Id} />}

      {(canUserEditModule(AccountSpace, moduleTypes.calls) ||
        canUserEditModule(AccountSpace, moduleTypes.tasks) ||
        canUserEditModule(AccountSpace, moduleTypes.tickets) ||
        canUserEditModule(AccountSpace, moduleTypes.interventions)) &&
        isPositiveStatus && (
          <LabelTitle title={<FormattedMessage id={"SERVICES"} />} />
        )}
      {canUserEditModule(AccountSpace, moduleTypes.calls) && (
        <CallFormButton
          hasAccess={isPositiveStatus}
          formProps={{ ticketId: entity.Id }}
        />
      )}
      {canUserEditModule(AccountSpace, moduleTypes.tasks) && (
        <TaskFormButton
          hasAccess={isPositiveStatus}
          formProps={{ ticketId: entity.Id }}
        />
      )}
      {canUserEditModule(AccountSpace, moduleTypes.tickets) && (
        <TicketFormButton
          hasAccess={isPositiveStatus}
          formProps={{ ticketId: entity.Id }}
        />
      )}
      {canUserEditModule(AccountSpace, moduleTypes.interventions) && (
        <InterventionFormButton
          hasAccess={isPositiveStatus}
          formProps={{ ticketId: entity.Id }}
        />
      )}
      {canUserEditModule(AccountSpace, moduleTypes.interventions) && (
        <TimeTrackingGridFormButton
          origin={{
            type: "Ticket",
            data: entity.Id
          }}
        />
      )}
      <LabelTitle title={<FormattedMessage id={"ACTIONS"} />} />
      {!sidebar && entity.CanEdit && (
        <TicketFormButton
          id={entity.Id}
          canEdit={entity.CanEdit}
          contextIcons
        />
      )}
      <TicketFormButton duplicate id={entity.Id} />
      <TicketsPdfButton id={entity.Id} />
      {canUserReadModule(AccountSpace, moduleTypes.emailConversations) && (
        <EmailConversationButton formProps={{ ticketId: entity.Id }} />
      )}
      <SendNotificationButton
        Schema={spaceTicketsSchema}
        toggler={sendNotificationToggler}
        Id={entity.Id}
      />
      <TicketsClipboardButton id={entity.Id} />
      <EntityReadButton value={entity} schema={spaceTicketsSchema} />
      <DeleteButtonContextMenu entity={entity} />
    </>
  );
};

export const TicketEntityActionsButton = ({ minimal, entity, ...props }) => {
  const [modalState, toggle] = useModalStatusReducer();

  return (
    <>
      <SendNotificationModal
        modalState={modalState}
        entity={entity}
        toggle={toggle}
      />
      <EntityPopup minimal={minimal}>
        <TicketEntityActionsChildren
          sendNotificationToggler={toggle}
          minimal={minimal}
          entity={entity}
          {...props}
        />
      </EntityPopup>
    </>
  );
};

export const TicketEntityGridButtons = ({
  entity,
  hideBreadCrumbs,
  minimal,
  noColors
}) => {
  // const adminAccess = isAdmin(AccountSpace);
  return (
    <React.Fragment>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="d-flex align-items-center"
      >
        {!hideBreadCrumbs && (
          <BreadcrumbsPopup
            className="mr-2 fs-14 d-inline-flex text-primary"
            originType={originTypes.ticket}
            minimal
            id={entity.Id}
          />
        )}
        <TicketFormButton
          minimal={minimal}
          className="mr-2 FormButtonClassID"
          canEdit={entity.CanEdit}
          id={entity.Id}
        />
        <TicketEntityActionsButton entity={entity} minimal={minimal} />
        {!noColors && <ColorFilterBars entity={entity} />}
      </div>
    </React.Fragment>
  );
};
export const ticketPipelineSettings = {
  field: "Ticket/PipelineId",
  pipelineStatusField: "Ticket/PipelineStatusId",
  type: PipelineTypeEnum.Ticket
};

export const useTicketFilters = () => {
  const intl = useIntl();
  return useMemo(() => {
    return [
      {
        propField: "Name",
        icon: <FontAwesomeIcon icon={faAlignJustify} />,
        titleId: "TITLE",
        field: "Ticket/Name",
        type: "string"
      },
      {
        propField: "Description",
        icon: <FontAwesomeIcon icon={faAlignJustify} />,
        titleId: "DESCRIPTION",
        field: "Ticket/NoTagDescription",
        type: "string"
      },
      // {
      //   propField: "CreationDate",
      //   icon: <FontAwesomeIcon icon={faCalendarAlt} />,
      //   titleId: "CREATION_DATE",
      //   field: "Ticket/CreationDateNoHours",
      //   type: "dateTime"
      // },
      createUserFilter({
        propField: "Manager",
        titleId: "ASSIGNED_TO",
        expeditedGetPropValue: (v) => v?.ManagerAccount,
        field: "Assigned/Id"
      }),
      {
        propField: "PipelineDate",
        icon: <FontAwesomeIcon icon={faCalendarAlt} />,
        titleId: "LAST_PIPELINE_STATUS_DATE",
        field: "Ticket/PipelineDate",
        type: "dateTime"
      },
      {
        propField: "StatusDate",
        icon: <FontAwesomeIcon icon={faCalendarAlt} />,
        titleId: "LAST_STATUS_DATE",
        field: "Ticket/StatusDate",
        type: "dateTime"
      },
      createTicketTagFilter({ requiresParams: true }),
      createTicketRatingFilter(),
      createContactFilter({ field: "Contact/Id" }),
      createUserFilter({
        propField: "Creator",
        titleId: "CREATED_BY",
        field: "Creator/Id",
        DescriptionComponent: ({ item, value }) => {
          return (
            <div>
              <FormattedMessage id={"CREATED_BY"} />:{" "}
              <ConnectedAccountProfile account={value} />
            </div>
          );
        }
      }),
      createIndirectContractFilter({
        field: "DirectIndirectContract/Id"
      }),
      createIndirectClientFilter({ field: "DirectIndirectClient/Id" }),
      {
        propField: "BeginDate",
        icon: <FontAwesomeIcon icon={faCalendarAlt} />,
        titleId: "ENTRY",
        field: "Ticket/ReceivedDateNoHours",
        type: "dateTime"
      },
      {
        propField: "CloseDate",
        icon: <FontAwesomeIcon icon={faCalendarAlt} />,
        titleId: "CLOSE_DATE",
        field: "Ticket/CloseDateNoHours",
        type: "dateTime"
      },
      createDepartmentFilter(),
      createOrganizationFilter({ field: "Company/Id" }),
      createTeamFilter(),
      createProjectStatusFilter({
        field: "PipelineStatus/Status"
      }),
      // createPipelineStatusFilter({
      //   pipelineSettings: ticketPipelineSettings
      // }),
      createUpgradedPipelineStatusFilter({
        pipelineSettings: ticketPipelineSettings
      }),
      createReadFilter({
        baseField: "Ticket"
      }),

      {
        propField: "Id",
        icon: <FontAwesomeIcon icon={faTicketSimple} />,
        title: intl.formatMessage({ id: "CASE_NR" }),
        field: "Ticket/Id",
        type: "string"
      },
      createIndirectDealFilter({
        field: "DirectIndirectDeal/Id"
      }),
      createUnreadFilter({
        baseField: "Ticket"
      }),
      createFollowingFilter({
        baseField: "Ticket"
      }),
      createNotFollowingFilter({
        baseField: "Ticket"
      }),
      createOriginFilter({
        baseFieldName: "Ticket",
        dropdownType: TicketTargetDropdown
      }),
      createIndirectProjectFilter({
        field: "DirectIndirectProject/Id"
      })
      // createIndirectTicketFilter({
      //   field: "DirectIndirectTicket/Id",
      // }),
    ];
  }, [intl]);
};
