import React, {
  Component,
  useEffect,
  useRef,
  useContext,
  useState,
  useCallback,
  useMemo
} from "react";
import CGrid, { GridRow } from "./CGrid";
import GridLoader from "../../Components/GridLoader/GridLoader";
import { ServerAwareFavoriteTree } from "./ServerAwareFavoriteTree";
import { injectIntl } from "react-intl";
import { useEntityValue } from "../../Hooks/EntityHooks";
import { useSpace } from "../../Contexts/SpaceContext";
import { useSpaceMultiplePost } from "../../Helpers/IOClient";
import { many } from "../../Helpers/SchemaHelper";
import querystring from "query-string";
import classnames from "classnames";
import { buildODataFilter } from "../../Helpers/ODataHelper";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";
import Input from "../Input/Input";
import "./ServerGrid.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/pro-light-svg-icons";
import {
  MainViewsSearchLSKey,
  MainViewsSearchCommunicator
} from "../../Helpers/MiscHelper";
import { useOdataQuery } from "../../Helpers/ODataApiHelper";
import {
  ConnectionStatusEnum,
  useConnectionQuery
} from "../../Containers/RealTime/RealTimeHelper";
import {
  ServerAwarePreferences,
  ServerAwarePreferencesKeyContext
} from "./ServerAwareGrid";
import {
  DetailsSettingsContext,
  DetailsValueIdContext
} from "../DetailsView2/DetailsView2Helper";
import { useTempFilters } from "../../Helpers/TempColumnIdHelper";
import {
  useUserConnectionStatus,
  useUserEntitiesHub
} from "../../Containers/RealTime/RealTimeUser";
import moment from "moment";
import ServerAwareViews from "./ServerAwareViews";
import { useColumnSizing } from "../VirtualGrid/VirtualServerAwareGrid";
import {
  ServerAwareColumnsContext,
  ServerAwareFilterColumnsContext
} from "./ServerAwareContexts";
import {
  ServerAwareColorFilters,
  useServerAwareColorFilterUpdater
} from "./ServerAwareColorFilters";
import { removeAllSelectedFilterBySchema } from "../FilterList/AdvancedFilter/AdvancedFilterColors/AdvancedFilterColorsLs";
import ServerAwareFavorite, {
  useQueryALL
} from "../../Containers/AppBar/ServerAwareFavorite";
import { FilterListOpenStateProvider } from "../FilterList/FilterListHelper";
import { useFavoriteOrViewChecker } from "../../Containers/AppBar/AppBarHelper";
import { usePageType } from "../EntitySummaries/EntitySummariesHelper";
import { ConversationTypeEnumFlags } from "../../Containers/Reception/ReceptionHelpers";
import { ClientDropdown } from "../../Containers/AdvancedMultiInputs";
const parseOdata = (urlParams) => {
  const params = urlParams;
  const filterString = params.$filter;

  if (filterString === undefined) return null;
  if (!filterString) return [];

  const filters = filterString.split(" and ");
  const resolvedFilters = [];

  for (const filter of filters) {
    const filterValues = filter.match(
      /([\w/]+) ([\w]+) ([\w\]'[\S ]+'+|[\d]+|'[\S ]+')/
    );

    const [, field, operator, value] = filterValues;
    resolvedFilters.push({
      field,
      operator,
      value: buildOdataValue(value)
    });
  }

  return resolvedFilters;
};

const buildOdataValue = (value) => {
  if (value.includes("datetime")) {
    const dateStr = value.substring(9, value.length - 1);
    return moment(dateStr).toDate();
  } else if (value.match(/'.*'/)) {
    //is string
    return value.substring(1, value.length - 1);
  } else {
    return Number(value);
  }
};

const buildFilters = ({ columns, urlParams }) => {
  // const props = this.props;
  let filters = [];
  const urlFilters = parseOdata(urlParams);

  if (urlFilters) {
    const mappedFields = {};

    for (const item of columns) {
      if (item.mappedFields) {
        for (const childField of item.mappedFields) {
          mappedFields[childField.urlField] = {
            ...item,
            field: childField
          };
        }
      } else {
        mappedFields[item.field] = item;
      }
    }

    for (const urlFilter of urlFilters) {
      const mappedField = mappedFields[urlFilter.field];
      if (!mappedField) continue;
      const { defaultFilters, ...data } = mappedField;
      const value = mappedField.convertUrlValue
        ? mappedField.convertUrlValue(urlFilter.field, urlFilter.value)
        : urlFilter.value;

      const filter = {
        active: true,
        ...urlFilter,
        ...mappedField,
        data,
        value,
        operator: urlFilter.operator,
        resolvedValue: mappedField.getResolvedAPIValue
          ? mappedField.getResolvedAPIValue(value)
          : !mappedField.resolveURLOnly && mappedField.getResolvedValue
          ? mappedField.getResolvedValue(value)
          : undefined
      };

      filters.push(filter);
    }
  } else {
    for (const item of columns) {
      if (!item.defaultFilters) continue;

      for (const filterItem of item.defaultFilters) {
        const { defaultFilters, ...rest } = item;
        const filter = {
          ...filterItem,
          data: rest
        };
        filters.push(filter);
      }
    }
  }
  return filters;
};

const buildOrderBy = (initialParams, urlParams) => {
  const { orderBy } = initialParams;
  const { $orderBy: orderByUrl } = urlParams;

  if (orderByUrl !== undefined) {
    const split = orderByUrl.split(",");
    if (orderByUrl.length === 0) return [];

    return split
      .map((e) => {
        const temp = e.split(" ");
        return {
          column: temp[0],
          type: temp[1]
        };
      })
      .slice(0, 2);
    // return split
    //   .map((e) => {
    //     const temp = e.split(" ");
    //     return {
    //       column: temp[0],
    //       type: temp[1]
    //     };
    //   })
    //   .slice(0, 2);
  } else if (orderBy) {
    const orderByArr = [{ column: orderBy.column, type: orderBy.type }];

    return orderByArr;
  } else return [];
};

const buildGroupBy = (initialParams, urlParams) => {
  const { groupBy } = initialParams;
  const { groupBy: groupByUrl } = urlParams;

  if (groupByUrl !== undefined) {
    if (!groupByUrl) return [];
    const split = groupByUrl.split(",");

    return split;
  } else if (groupBy) {
    return groupBy;
  } else return [];
};

const buildAdvancedFiltersFromUrl = (urlParams) => {
  const filtersS = urlParams.advancedFilters;
  if (!filtersS) return undefined;
  const filters = filtersS.split(",");
  for (let i = 0; i < filters.length; i++) {
    filters[i] = Number(filters[i]);
  }

  return filters;
};

export const ServerGridParams = (initialParams) => {
  const resolvedParams =
    typeof initialParams === "function" ? initialParams() : initialParams || {};

  const { filter, filterColumns } = resolvedParams;

  const urlParams = querystring.parse(window.location.search);
  const query = urlParams.query || "";
  const skip = Number(urlParams.skip) || 0;
  const obj = {
    ...urlParams,
    $orderBy: buildOrderBy(resolvedParams, urlParams),
    groupBy: buildGroupBy(resolvedParams, urlParams),
    skip,
    top: urlParams.$top
      ? Number(urlParams.$top) > 25
        ? 25
        : // : Number(urlParams.$top)
          25
      : 25,
    count: true,
    filter: filterColumns
      ? buildFilters({ columns: filterColumns, urlParams })
      : filter || [],
    advancedFilters: buildAdvancedFiltersFromUrl(urlParams),
    query
  };

  if (!obj.$orderBy) delete obj.$orderBy;

  return obj;
};

const getRowKey = (v) => v;

// const getLocalStorageSize = (schema) => {
//   let gridColumnLayout = JSON.parse(
//     window.localStorage.getItem("ar-grid-columns-size")
//   );

//   return (gridColumnLayout && schema && gridColumnLayout[schema.name]) || {};
// };

export const ServerAwareGrid = React.memo(function ServerAwareGrid(props) {
  const {
    params,
    onParamsChange,
    isLoading,
    results,
    currentPage,
    pages,
    pageSize,
    count,
    schema
  } = useContext(GridRequestContext);

  const updateParams = useCallback(
    (newParams) => {
      onParamsChange((params) => {
        return {
          ...params,
          ...newParams
        };
      });
    },
    [onParamsChange]
  );
  const orderTimeoutRef = useRef();
  const handleOrderChange = useCallback(
    (data) => {
      //
      let newParams = {
        $orderBy: data
      };

      clearTimeout(orderTimeoutRef.current);

      orderTimeoutRef.current = setTimeout(() => {
        updateParams(newParams);
      }, 600);
    },
    [updateParams]
  );

  const handlePageChange = useCallback(
    ({ page }) => {
      const { top: pageSize } = params;

      // this.setState({
      //   currentPage: page
      // });

      updateParams({
        skip: pageSize * (page - 1)
      });
    },
    [params, updateParams]
  );

  const buildSkeletonColumns = () => {
    const { columns } = props;
    const filteredColumns = columns.filter((e) => {
      if (e) return e;
      else return null;
    });
    const newColumns = [];
    for (const column of filteredColumns) {
      if (!column) continue;
      if (column && column.selectable) continue;

      let CellComp = (Data) => <div className="grid-skeleton" />;

      // CellComp = CellComp.bind(this);

      newColumns.push({
        ...column,
        Cell: CellComp
      });
    }
    return newColumns;
  };

  const getColmuns = () => {
    const { columns } = props;

    if (!results) return buildSkeletonColumns();
    else return columns;
  };

  const getItems = () => {
    if (results) return results;

    const newItems = [];
    for (let page = 0; page < 20; page++) {
      newItems.push(null);
    }

    return newItems;
  };

  // const selector = useCallback(
  //   (value, link) => {
  //     const obj = {
  //       Id: value.Id
  //     };

  //     for (let i = 0; i < groupBy.length; i++) {
  //       const element = groupBy[i];
  //       obj[`groupedProp${i}`] = value[element.field];
  //     }

  //     return {
  //       Id,
  //       groupedProp1,
  //       groupedProp2
  //     };

  //     return obj;
  //   },
  //   [groupBy]
  // );

  const { rowComponent, getValue, className, groupBy, ...rest } = props;

  const { $orderBy } = params;

  //   const selector = useCallback(
  //     (value, link) => {
  //       const Id = value.Id;

  //       const groups = [];

  //       if (Array.isArray(groupBy)) {
  //         for (const g of groupBy) {
  //           groups.push(value[g]);
  //         }
  //       }
  //       return {
  //         Id,
  //         groups
  //       };
  //     },
  //     [groupBy]
  //   );

  //   const connectedResults = useMultipleEntityValueSelector(
  //     spaceCallsSchema,
  //     results || [],
  //     selector
  //   );

  // ["Fernando", 1]

  //     return obj;
  //   },
  //   [groupBy]
  // );

  // ["Fernando", 1]  ["Fernando", 2] ["Ruben", 3]
  // [{Id, groups}, {Id, groups}, {Id, groups}]

  // [[{"Fernando", 1},{ "Fernando", 2}], [{"Ruben", 3}]]

  // const updateLocalStorageSizes = useCallback(
  //   (result) => {
  //     // let gridColumnLayout = JSON.parse(
  //     //   window.localStorage.getItem("ar-grid-columns-size")
  //     // );
  //     // if (gridColumnLayout) {
  //     //   gridColumnLayout[schema.name] = result;
  //     //   localStorage.setItem(
  //     //     "ar-grid-columns-size",
  //     //     JSON.stringify(gridColumnLayout)
  //     //   );
  //     // } else {
  //     //   const gridColumnLayout = { [schema.name]: result };
  //     //   localStorage.setItem(
  //     //     "ar-grid-columns-size",
  //     //     JSON.stringify(gridColumnLayout)
  //     //   );
  //     // }

  // 		// setColumnSizes(getLocalStorageSize(schema));
  // 		let gridSavedViews = JSON.parse(
  //       window.localStorage.getItem(`ar-grid-saved-views-space-${space.Id}`)
  //     );
  //     const viewGridViews = gridSavedViews[schema.name];
  //     const index = viewGridViews.findIndex((e) => e.id === viewPref);
  //     let resolvedIndex = index === -1 ? 0 : index;
  //     let viewGridObject = viewGridViews[resolvedIndex] || {};
  //     viewGridObject.columnSize = result;
  //     viewGridViews[resolvedIndex] = viewGridObject;
  //     gridSavedViews[schema.name] = viewGridViews;
  //     localStorage.setItem(
  //       `ar-grid-saved-views-space-${space.Id}`,
  //       JSON.stringify(gridSavedViews)
  //     );
  //     updateLsViews();
  //   },
  //   [schema]
  // );

  // const [columnSizes, setColumnSizes] = useState(() => {
  //   return getLocalStorageSize(schema);
  // });

  const { updateLocalStorageSizes, columnSizes } = useColumnSizing(schema);

  const serverFiltering = useMemo(() => {
    return {
      enabled: true,
      page: currentPage,
      pages: pages
    };
  }, [currentPage, pages]);

  return (
    <div className={classnames(className, "d-flex of-hidden w-100 h-100")}>
      <CGrid
        //sortable
        schemaName={schema.name}
        getRowKey={getRowKey}
        {...rest}
        onSort={handleOrderChange}
        sort={$orderBy}
        className="m-h-100 flex-1 w-100 ar-main-grid"
        serverFiltering={serverFiltering}
        columnsSize={columnSizes}
        onColumnsSizeChange={updateLocalStorageSizes}
        // messages={{
        //   today: intl.formatMessage({ id: "today" }),
        //   yesterday: intl.formatMessage({ id: "yesterday" })
        // }}
        isLoading={isLoading}
        // filter={filter}
        groupBy={groupBy}
        rowComponent={rowComponent}
        page={currentPage}
        pageSize={pageSize}
        totalItems={count}
        headerScroll={false}
        //   change={data => console.log(data)}
        columns={getColmuns()}
        onPageChange={handlePageChange}
        data={getItems()}
        getValue={getValue}
      />
    </div>
  );
});

class ServerGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
      params: {},
      groupBy: []
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.value)
      return {
        params: props.value
      };
    else return null;
  }

  handleOrderChange = (data) => {
    let newParams = {
      orderBy: data
    };
    this.updateParams(newParams);
  };

  handleFilterChange = (data) => {
    let newParams = {
      filter: [...data]
    };

    this.updateParams(newParams);
  };

  handlePageChange = ({ page }) => {
    const { top: pageSize } = this.state.params;

    this.setState({
      currentPage: page
    });

    this.updateParams({
      skip: pageSize * (page - 1)
    });
  };

  updateParams = (newParams) => {
    const { onParamsChange } = this.props;

    let updatedParams = {
      ...this.props.value,
      ...newParams
    };

    if (this.props.value) onParamsChange(updatedParams);
    else
      this.setState({
        params: updatedParams
      });
  };

  buildSkeletonColumns = () => {
    const { columns } = this.props;
    const newColumns = [];

    for (const column of columns) {
      if (column.selectable) continue;

      let CellComp = (Data) => <div className="grid-skeleton" />;

      CellComp = CellComp.bind(this);

      newColumns.push({
        ...column,
        Cell: CellComp
      });
    }
    return newColumns;
  };

  getColmuns = () => {
    const { columns, firstLoading } = this.props;
    if (firstLoading) return this.buildSkeletonColumns();
    else return columns;
  };

  getItems = () => {
    const { data, firstLoading } = this.props;

    if (!firstLoading) return data;

    const newItems = [];
    for (let page = 0; page < 20; page++) {
      newItems.push(null);
    }
    return newItems;
  };

  render() {
    const { currentPage, params } = this.state;
    const { filter, groupBy } = params;
    const {
      data,
      pages,
      isLoading,
      pageSize,
      getValue,
      columns,
      firstLoading,
      rowComponent,
      intl,
      count,
      className,
      ...rest
    } = this.props;

    const { orderBy } = params;

    return (
      <div className={classnames("d-flex w-100", className)}>
        <CGrid
          // sortable
          onSort={this.handleOrderChange}
          sort={orderBy}
          className="m-h-100 flex-1 w-100"
          serverFiltering={{
            enabled: true,
            page: currentPage,
            pages: pages
          }}
          messages={{
            today: intl.formatMessage({ id: "today" }),
            yesterday: intl.formatMessage({ id: "yesterday" })
          }}
          isLoading={!firstLoading && isLoading}
          loadingComponent={<GridLoader />}
          filter={filter}
          groupBy={groupBy}
          rowComponent={rowComponent}
          page={currentPage}
          pageSize={pageSize}
          totalItems={count}
          headerScroll
          //   change={data => console.log(data)}
          columns={this.getColmuns()}
          onPageChange={this.handlePageChange}
          data={this.getItems()}
          getValue={getValue}
          {...rest}
        />
      </div>
    );
  }
}

export default injectIntl(ServerGrid);

// class ServerAwareWrapper extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {};
//   }

//   // shouldComponentUpdate(nextProps, nextState) {
//   //   // filter changed but its request still hasn't started | wait for it
//   //   if (
//   //     this.props.serverState.params &&
//   //     nextProps.serverState.params &&
//   //     this.props.serverState.params.filter !==
//   //       nextProps.serverState.params.filter
//   //   )
//   //     return false;
//   //   else return true;
//   // }

//   render() {
//     const { WrappedComponent, ...rest } = this.props;
//     return <WrappedComponent {...rest} />;
//   }
// }

// const getPageFromState = (spaceId, state, schema) => {
//   let ServerAwarePage = state.SpaceGridViews[spaceId];

//   if (ServerAwarePage) {
//     ServerAwarePage = ServerAwarePage[schema ? schema.name : schema];
//   }
//   return ServerAwarePage;
// };

// export const fetchGridDataFromSchema = (schema, getEndpoint) => {
//   const actionName = getActionName(schema.name);
//   return createMainSpaceRequest({
//     requestName: `FETCH_${actionName}`,
//     getEndpoint: getEndpoint ? getEndpoint : schema.getEndpoint,
//     getPageFromState: (spaceId, state) =>
//       getPageFromState(spaceId, state, schema),
//     schema: [schema],
//     dispatchBody: {
//       internalType: "gridView",
//       name: schema.name,
//       actionName
//     }
//   });
// };

export const buildGridUrl = (endpoint, schema, query, spaceId) => {
  if (!endpoint) return `${schema.getEndpoint(spaceId)}${query}`;

  let rightEndpoint;
  if (endpoint.includes("?"))
    rightEndpoint = `${endpoint}${query.replace("?", "&")}`;
  else rightEndpoint = `${endpoint}${query}`;

  return `spaces/${spaceId}/${rightEndpoint}`;
};

export const useGridQuery = (endpoint, schema, query, ...args) => {
  const { Id: spaceId } = useSpace();
  const dataRef = useRef();
  const rSchema = useMemo(() => {
    return {
      d: {
        results: many(schema)
      }
    };
  }, [schema]);

  const resolvedEndpoint = useMemo(() => {
    return buildGridUrl(endpoint, schema, query, spaceId);
  }, [endpoint, query, schema, spaceId]);

  const state = useConnectionQuery(resolvedEndpoint, rSchema, ...args);

  if (state.data) dataRef.current = state.data.d;

  return { ...state, data: dataRef.current };
};

export const ServerAwareContext = React.createContext();

export const useServerAwareState = () => {
  return useContext(ServerAwareContext);
};

export const ServerAwareQueryInput = ({
  className,
  placeholder,
  saveOnClear,
  onChange,
  ...rest
}) => {
  const { onParamsChange, params } = useServerAwareState();
  const { view } = params;

  const ViewPref = useMemo(() => {
    return params.viewPref;
  }, [params.viewPref]);

  const completedFirstRun = useRef();

  useEffect(() => {
    if (completedFirstRun.current) {
      setValue(params.query);
    } else {
      completedFirstRun.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ViewPref]);

  const inputRef = useRef();
  useEffect(() => {
    if (view) return;
    inputRef.current.focus();
  }, [view]);

  const [value, setValue] = useState(params.query);

  const [focused, setIsFocused] = useState(false);
  const timeoutRef = useRef();
  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const handleFocus = (e) => {
    setIsFocused(true);
  };
  const handleBlur = (e) => {
    setIsFocused(false);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    clearTimeout(timeoutRef.current);
    setValue(value);
    timeoutRef.current = setTimeout(() => {
      if (onChange) onChange(e);
      onParamsChange({
        query: value
      });
    }, 1000);
  };

  return (
    <div
      className={classnames(
        "ssi-control rounded ar-server-aware-input flex-1 d-flex align-items-center p-2",
        className,
        {
          selected: focused
        }
      )}
    >
      <div className="ar-server-aware-input-icon mr-2">
        {/* {isLoading ? (
          <LoaderSpinner className="text-primary" />
        ) : (
          <FontAwesomeIcon icon={faSearch} className="text-primary" />
        )} */}
        <FontAwesomeIcon icon={faSearch} className="text-primary" />
      </div>
      <Input
        ref={inputRef}
        id="ServerInputQueryBar"
        value={value}
        name="search-text"
        autoComplete="off"
        onChange={handleChange}
        placeholder={placeholder}
        onFocus={handleFocus}
        maxLength={256}
        onBlur={() => {
          if (handleBlur) handleBlur();
        }}
        className="flat-input p-0"
        {...rest}
      />
      {value.length > 0 && (
        <div
          className="cursor-pointer px-2"
          onClick={() => {
            const e = {};
            e.target = { value: "" };
            handleChange(e);
          }}
        >
          <FontAwesomeIcon icon={faTimes} className="text-warning" />
        </div>
      )}
    </div>
  );
};

export const ServerAwareClientInput = ({
  className,
  placeholder,
  saveOnClear,
  onChange,
  ...rest
}) => {
  const { onParamsChange, params } = useServerAwareState();

  const [value, setValue] = useState(params.clientId);

  const handleChange = (e) => {
    setValue(e);
    onParamsChange({
      additionalParams: {
        clientId: e
      }
    });
    onChange(e);
  };

  return (
    <ClientDropdown
      className={className}
      value={value}
      fixedWidth="500"
      onChange={handleChange}
    />
  );
};

export const ServerAwareProvider = ({
  children,
  moduleType,
  schema,
  gridSchema,
  schemaName,
  ...rest
}) => {
  const { favoriteId, viewPref } = useQueryALL();
  const result = useFavoriteOrViewChecker();
  if (result) {
    return (
      <ServerAwareFavorite key={favoriteId} favoriteId={favoriteId}>
        <ServerAwareFavoriteTree favoriteId={favoriteId}>
          <ServerAwareColorFilters schema={gridSchema || schema}>
            <InnerServerAware
              moduleType={moduleType}
              gridSchema={gridSchema}
              schema={schema}
              {...rest}
            >
              {children}
            </InnerServerAware>
          </ServerAwareColorFilters>
        </ServerAwareFavoriteTree>
      </ServerAwareFavorite>
    );
  }
  // else if(viewPref){
  // 	return(
  // 		<ServerAwareViewPref viewName={schemaName || schema.name}>
  //     <ServerAwarePreferences moduleType={moduleType}>
  //       <ServerAwareColorFilters schema={gridSchema || schema}>
  //         <InnerServerAware
  //           moduleType={moduleType}
  //           gridSchema={gridSchema}
  //           schema={schema}
  //           {...rest}
  //         >
  //           {children}
  //         </InnerServerAware>
  //       </ServerAwareColorFilters>
  //     </ServerAwarePreferences>
  //   </ServerAwareViewPref>
  // 	)
  // }

  return (
    <ServerAwareViews viewPref={viewPref} viewName={schemaName || schema.name}>
      <ServerAwarePreferences moduleType={moduleType}>
        <ServerAwareColorFilters schema={gridSchema || schema}>
          <InnerServerAware
            moduleType={moduleType}
            gridSchema={gridSchema}
            schema={schema}
            {...rest}
          >
            {children}
          </InnerServerAware>
        </ServerAwareColorFilters>
      </ServerAwarePreferences>
    </ServerAwareViews>
  );
};

export const InnerServerAware = ({
  schema,
  endpoint,
  children,
  filterColumns,
  defaultOrderBy,
  listenAllMatches,
  moduleType,
  extraRefetch,
  gridSchema,
  columns
}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();
  const refetchRef = useRef();

  const refetch = useCallback(() => {
    if (typeof extraRefetch === "function") extraRefetch();
    if (!refetchRef.current) return;

    refetchRef.current();
  }, [extraRefetch]);

  // console.log(initialParams);
  // console.log(defaultOrderBy);
  const resolvedFilterColumns = useTempFilters(filterColumns, moduleType);

  const resolvedInitialParams = useMemo(() => {
    // console.log(defaultOrderBy);
    return {
      filterColumns: resolvedFilterColumns,
      orderBy: defaultOrderBy
    };
  }, [defaultOrderBy, resolvedFilterColumns]);

  const [params, setParams] = useState(() =>
    ServerGridParams(resolvedInitialParams)
  );
  const [paramsUrl, setparamsUrl] = useState(location.search);
  const addUrlFilters = (queryParams, params, oldParams) => {
    const {
      filter,
      $orderBy,
      top,
      advancedFilters,
      pipeline,
      groupBy,
      conversationTypes
    } = params;

    if (advancedFilters)
      queryParams.advancedFilters = advancedFilters.join(",");

    if (filter !== oldParams.filter) {
      const filterParam = buildODataFilter(filter, true);
      queryParams.$filter = filterParam;
    }

    if ($orderBy !== oldParams.$orderBy) {
      if (!$orderBy) delete queryParams.$orderBy;

      const tempArr = $orderBy;

      let orderBystring = "";
      for (let i = 0; i < tempArr.length - 1; i++) {
        orderBystring += `${tempArr[i].column} ${tempArr[i].type},`;
      }
      if (tempArr.length > 0)
        orderBystring += `${tempArr[tempArr.length - 1].column} ${
          tempArr[tempArr.length - 1].type
        }`;
      queryParams.$orderBy = orderBystring;
    }

    if (groupBy !== oldParams.groupBy) {
      if (!groupBy) delete queryParams.groupBy;

      const tempArr = groupBy;

      let groupBystring = "";
      for (let i = 0; i < tempArr.length - 1; i++) {
        groupBystring += `${tempArr[i]},`;
      }
      if (tempArr.length > 0) groupBystring += `${tempArr[tempArr.length - 1]}`;
      queryParams.groupBy = groupBystring;
    }

    if (top) queryParams.$top = top;

    if (pipeline) queryParams.pipeline = pipeline;
    else delete queryParams.pipeline;

    if (conversationTypes) {
      queryParams.conversationTypes = conversationTypes;
    }
  };

  const preferenceKey = useContext(ServerAwarePreferencesKeyContext);
  const post = useSpaceMultiplePost();

  const setFilterUrl = useCallback(
    (params, oldParams) => {
      const { query, skip, additionalParams } = params;
      let queryParams = querystring.parse(window.location.search);
      addUrlFilters(queryParams, params, oldParams);

      if (query) {
        queryParams.query = query;
      } else {
        delete queryParams.query;
      }

      if (skip) {
        queryParams.skip = skip;
      } else {
        delete queryParams.skip;
      }

      if (additionalParams) {
        for (const param in additionalParams) {
          if (additionalParams.hasOwnProperty(param)) {
            const element = additionalParams[param];
            queryParams[param] = element;
          }
        }
      }

      const newSearch = querystring.stringify(queryParams);
      // post("Preference/Set", {
      //   Key: preferenceKey,
      //   Value: newSearch
      // });
      // saveToLocalStorage(newSearch, schema);

      history.push({
        search: newSearch
      });
    },
    [history]
  );

  const onParamsChange = useCallback(
    (p) => {
      const updateParams = typeof p === "function" ? p(params) : p;
      if (updateParams === params) return params;

      const newParams = {
        ...params,
        ...updateParams
      };

      if (
        newParams.advancedFilters !== params.advancedFilters ||
        newParams.filter !== params.filter ||
        newParams.query !== params.query
      )
        newParams.skip = 0;

      // if(newParams.advancedFilter) delete newParams.filter;

      setFilterUrl(newParams, params);
    },
    [params, setFilterUrl]
  );
  // const dataObj = usePipelineQuery();

  // const numOfPipelines = dataObj ? (dataObj.Data ? dataObj.Data.length : 0) : 0;

  const updateSelectedColorFilter = useServerAwareColorFilterUpdater();

  const onreset = useCallback(() => {
    if (params.pipeline && params.view && params.favoriteId) {
      history.push({
        search: `pipeline=${params.pipeline}&view=${params.view}&favoriteId=${params.favoriteId}`
      });
    } else if (params.pipeline && params.view && params.viewPref) {
      history.push({
        search: `pipeline=${params.pipeline}&view=${params.view}&viewPref=${params.viewPref}`
      });
    } else if (params.view && params.favoriteId) {
      history.push({
        search: `view=${params.view}&favoriteId=${params.favoriteId}`
      });
    } else if (params.view && params.viewPref) {
      history.push({
        search: `view=${params.view}&viewPref=${params.viewPref}`
      });
    } else if (params.favoriteId) {
      history.push({
        search: `favoriteId=${params.favoriteId}`
      });
    } else {
      history.push({
        search: `viewPref=${params.viewPref}`
      });
    }
    removeAllSelectedFilterBySchema(gridSchema || schema);
    updateSelectedColorFilter([]);

    // onParamsChange({
    //   query: "",
    //   skip: 0,
    //   pipeline: numOfPipelines > 1 ? null : params.pipeline
    // });
    //   history.push({
    //     search: null
    //   });
  }, [
    gridSchema,
    history,
    params.favoriteId,
    params.pipeline,
    params.view,
    params.viewPref,
    schema,
    updateSelectedColorFilter
  ]);

  const matchPathRef = useRef();
  matchPathRef.current = match.path;

  useEffect(() => {
    if (location.search !== paramsUrl) {
      const newParams = ServerGridParams();
      setParams(newParams);
      setparamsUrl(location.search);
    }
  }, [location.search, paramsUrl]);

  useEffect(() => {
    const unsubscribe = history.listen((location, action) => {
      if (!listenAllMatches && matchPathRef.current !== location.pathname)
        return;
      const newParams = ServerGridParams(resolvedInitialParams);
      setParams(newParams);
      setparamsUrl(location.search);
      // if (preferenceKey) {
      //   post("Preference/Set", {
      //     Key: preferenceKey,
      //     Value: JSON.stringify({
      //       search: location.search
      //     })
      //   });
      // }
    });
    return unsubscribe;
  }, [history, resolvedInitialParams, post, preferenceKey, listenAllMatches]);

  // useEffect(() => {
  //   saveToLocalStorage(paramsUrl.substring(1), schema);
  // }, [paramsUrl, schema]);

  const consumerRef = useRef();

  const value = useMemo(() => {
    return {
      params: params,
      paramsUrl,
      onParamsChange: onParamsChange,
      Reset: onreset,
      schema,
      endpoint,
      gridSchema,
      consumerRef,
      history,
      moduleType,
      refetchRef,
      refetch
    };
  }, [
    params,
    paramsUrl,
    onParamsChange,
    onreset,
    schema,
    endpoint,
    gridSchema,
    history,
    moduleType,
    refetch
  ]);

  return (
    <ServerAwareContext.Provider value={value}>
      <FilterListOpenStateProvider schema={schema}>
        <ServerAwareFilterColumnsContext.Provider value={resolvedFilterColumns}>
          <ServerAwareColumnsContext.Provider value={columns}>
            {children}
          </ServerAwareColumnsContext.Provider>
        </ServerAwareFilterColumnsContext.Provider>
      </FilterListOpenStateProvider>
    </ServerAwareContext.Provider>
  );
};

export const GridRequestContext = React.createContext();

export const useGridRequest = () => useContext(GridRequestContext);

const getResolvedDetailField = (gridField, customGridFields, moduleType) =>
  customGridFields && customGridFields[moduleType]
    ? customGridFields[moduleType]
    : gridField;

export const useDetailAwareParams = (
  params,
  moduleType,
  disableDetailsFilter
) => {
  const detailSettings = useContext(DetailsSettingsContext);
  const detailsEntityId = useContext(DetailsValueIdContext);

  const resolvedParams = useMemo(() => {
    if (!detailSettings) return params;
    const { gridField, customGridFields } = detailSettings;
    if (disableDetailsFilter) {
      return {
        ...params
      };
    }
    return {
      ...params,
      filter: [
        {
          operator: "eq",
          field: getResolvedDetailField(
            gridField,
            customGridFields,
            moduleType
          ),
          value: detailsEntityId,
          data: {
            type: typeof detailsEntityId === "number" ? "number" : "string"
          }
        },
        ...params.filter
      ]
    };
  }, [
    detailSettings,
    detailsEntityId,
    disableDetailsFilter,
    moduleType,
    params
  ]);
  return resolvedParams;
};

export const GridRequestProvider = ({
  children,
  createNormalizationAction,
  extraRefetch,
  toggleColumnChooser
}) => {
  const {
    endpoint,
    schema,
    params,
    onParamsChange,
    consumerRef,
    moduleType,
    refetchRef,
    gridSchema
  } = useServerAwareState();
  const queryParams = useDetailAwareParams(params, moduleType);

  const query = useOdataQuery(queryParams);

  const qState = useGridQuery(endpoint, schema, query, {
    cache: false,
    createNormalizationAction
  });
  const { skip, top } = params;

  const { data, error, loading, refetch } = qState;
  useEffect(() => {
    refetchRef.current = refetch;

    return () => (refetchRef.current = undefined);
  }, [refetch, refetchRef]);
  const status = useUserConnectionStatus();

  const prevStatusRef = useRef();

  const handleRefetch = useCallback(() => {
    refetch();
    if (typeof extraRefetch === "function") extraRefetch();
  }, [extraRefetch, refetch]);

  useEffect(() => {
    if (
      Boolean(data) &&
      status === ConnectionStatusEnum.connected &&
      prevStatusRef.current !== ConnectionStatusEnum.connected
    )
      refetch();

    prevStatusRef.current = status;
  }, [data, refetch, status]);

  const value = useMemo(() => {
    //;
    let results;
    if (data && (!loading || data.results.length > 0)) results = data.results;

    return {
      toggleColumnChooser,
      error: error,
      hasFetched: Boolean(data),
      isLoading: loading,
      params: params,
      count: data && data.__count,
      currentPage: Math.ceil((skip + 1) / top) || 1,
      pageSize: top,
      pages: data ? Math.ceil(data.__count / top) : 1,
      results: results,
      onParamsChange: onParamsChange,
      refetch: handleRefetch,
      schema,
      gridSchema
    };
  }, [
    data,
    error,
    gridSchema,
    handleRefetch,
    loading,
    onParamsChange,
    params,
    schema,
    skip,
    toggleColumnChooser,
    top
  ]);

  const { results } = value;

  const entitiesHub = useUserEntitiesHub();
  const pageType = usePageType();

  useEffect(() => {
    if (entitiesHub && pageType && results?.length > 0) {
      const connector = () => {
        try {
          entitiesHub.invoke("EnterView", pageType, results);
        } catch (error) {
          console.log(error);
        }
      };

      const listener = () => {
        setTimeout(() => {
          connector();
        }, 150);
      };

      listener();
    }
  }, [entitiesHub, pageType, results]);

  const builtRef = useRef(false);

  useEffect(() => {
    if (!builtRef.current) {
      return () => {
        if (entitiesHub && pageType) {
          try {
            entitiesHub.invoke("LeaveView", pageType);
          } catch (error) {
            console.log(error);
          }
        }
      };
    } else {
      builtRef.current = true;
    }
  }, [entitiesHub, pageType]);

  useEffect(() => {
    if (!consumerRef) return;

    consumerRef.current = value;
    return () => {
      consumerRef.current = undefined;
    };
  }, [consumerRef, value]);

  return (
    <GridRequestContext.Provider value={value}>
      {children}
    </GridRequestContext.Provider>
  );
};

const CardRequestContext = React.createContext();

export const useCardRequestProvider = () => useContext(CardRequestContext);

export const CardRequestProvider = ({ children, onItemSelect }) => {
  const {
    endpoint,
    schema,
    params,
    onParamsChange,
    // consumerRef,
    moduleType,
    // refetchRef,
    gridSchema
  } = useServerAwareState();

  const queryParams = useDetailAwareParams(params, moduleType);

  // const [results, setResults] = useState([]);

  // const mountRef = useRef();

  // useEffect(() => {
  //   if (!mountRef.current) {
  //     mountRef.current = true;
  //     return;
  //   }
  //   setResults([]);
  // }, []);

  // const [hasMore, setHasMore] = useState(true);

  const query = useOdataQuery(queryParams);
  const qState = useGridQuery(endpoint, schema, query, {
    cache: false

    // onSuccess: ({ data }) => {
    // 	const {results} = data.d
    // 	if(results.length < top){
    // 		setHasMore(false)
    // 	}
    //   setResults((e) => {
    //     return [...e, ...data.d.results];
    //   });
    // }
  });

  const { data, error, loading, refetch } = qState;
  const { top, skip } = params;
  // const loadMore = useMemo(() => {
  // 	if (error || loading) return undefined;
  // 	else if(!hasMore) return undefined
  //   else
  //     return () => {
  //       setSkip((e) => {
  //         return e + 20;
  //       });
  //     };
  // }, [error, hasMore, loading]);

  const value = useMemo(() => {
    return {
      error: error,
      hasFetched: Boolean(data),
      isLoading: loading,
      params: params,
      count: data && data.__count,
      results: data?.results || [],
      onParamsChange: onParamsChange,
      currentPage: Math.ceil((skip + 1) / top) || 1,
      pageSize: top,
      pages: data ? Math.ceil(data.__count / top) : 1,
      refetch,
      schema,
      gridSchema,
      onItemSelect
    };
  }, [
    data,
    error,
    gridSchema,
    loading,
    onItemSelect,
    onParamsChange,
    params,
    refetch,
    schema,
    skip,
    top
  ]);

  return (
    <CardRequestContext.Provider value={value}>
      {children}
    </CardRequestContext.Provider>
  );
};

// export const withServerAwareData = (WrappedComponent, options) => {
//   const { defaultParams, schema, getEndpoint } = options;
//   const getServerData = fetchGridDataFromSchema(schema, getEndpoint);

//   class ServerAwareData extends React.Component {
//     constructor(props) {
//       super(props);
//       this.state = {
//         // params: { ...ServerGridParams(props, defaultParams), ...defaultParams }
//       };
//     }

//     static getDerivedStateFromProps(props, { params }) {
//       return {
//         params: { ...defaultParams, ...params, ...ServerGridParams(props) }
//       };
//     }

//     updateParams = newParams => {
//       this.setState({
//         params: newParams
//       });

//       let space = this.props.userAccountSpace.Space;
//       this.props.getServerData(space.Id, newParams);
//     };

//     getServerData = () => {
//       const { userAccountSpace } = this.props;
//       let space = userAccountSpace.Space;
//       this.props.getServerData(space.Id, this.state.params);
//     };

//     componentDidUpdate(prevProps) {
//       const { ServerAwarePage } = this.props;
//       if (
//         this.props.filter !== prevProps.filter ||
//         (ServerAwarePage && ServerAwarePage.shouldUpdate)
//       ) {
//         this.getServerData();
//       }
//     }

//     componentDidMount() {
//       const { userAccountSpace } = this.props;
//       let space = userAccountSpace.Space;

//       this.props.getServerData(space.Id, this.state.params);
//     }

//     render() {
//       const { ServerAwarePage } = this.props;

//       const currentState = ServerAwarePage;

//       const value = {
//         error: currentState && currentState.error,
//         hasFetched: Boolean(currentState && currentState.value),
//         isFetching: currentState && currentState.isFetching,
//         params: this.state.params,
//         count: currentState && currentState.value && currentState.value.count,
//         currentPage:
//           currentState && currentState.value && currentState.value.currentPage,
//         pageSize:
//           (currentState && currentState.value && currentState.value.pageSize) ||
//           50,
//         pages: currentState && currentState.value && currentState.value.pages,
//         results:
//           currentState && currentState.value && currentState.value.results
//       };

//       return (
//         <ServerAwareWrapper
//           WrappedComponent={WrappedComponent}
//           {...this.props}
//           serverState={value}
//           updateServerParams={this.updateParams}
//         />
//       );
//     }
//   }
//   const mapStateToProps = (state, ownProps) => {
//     const spaceId = ownProps.userAccountSpace.Space.Id;

//     return {
//       ServerAwarePage: getPageFromState(spaceId, state, schema)
//     };
//   };

//   const mapDispatchToProps = dispatch => {
//     return {
//       getServerData: (spaceId, params) =>
//         dispatch(getServerData(spaceId, params))
//     };
//   };

//   return withSpatialUser(
//     withServerFilter(
//       connect(mapStateToProps, mapDispatchToProps)(ServerAwareData)
//     )
//   );
// };

export const createConnectedRow = ({ schema, getKey, getCompleteValue }) => {
  if (!getKey) getKey = (props) => props.value;

  const ConnectedRow = React.memo((props) => {
    const resolvedValue = getCompleteValue
      ? getCompleteValue(getKey(props))
      : // eslint-disable-next-line react-hooks/rules-of-hooks
        useEntityValue(schema, getKey(props));
    const { value, ...rest } = props;
    return <GridRow value={resolvedValue} {...rest} />;
  });

  return ConnectedRow;
};
